import * as React from "react";
import ContactForm from "../components/ContactForm";
import Seo from "../components/Seo";

const ContactUsPage = () => {
  return (
    <>
      <Seo title="Contact Us" />
      <ContactForm />
    </>
  );
};

export default ContactUsPage;
